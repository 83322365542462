/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";




%wh100V{
  width: 100vw;
  height: 100vh;
}

%wh100PC{
  width: 100%;
  height: 100%;
}


/* Capas al 100% */
%overlay100{
  @extend %wh100V;
  position: absolute;
  top: 0;
  left: 0;
}


.text-base{
  font-family: Roboto, "Helvetica Neue", sans-serif;
}



:root {
  --app-height: 100%;
}

*{
  -webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0;margin:0;}

html {
  -webkit-text-size-adjust: 100%;
}

html, body{
  @extend %wh100V , .text-base;
  padding: 0;
  margin: 0;
  line-break: auto;
  height: var(--app-height);
}





/* ***************************************************** DIRECTION ***************************************************** */

.ltr{
  direction: ltr
    //unicode-bidi: bidi-override !important
}

.rtl{
  direction: rtl
    //unicode-bidi: bidi-override !important
}




.visible{
  visibility: inherit;

}

.invisible{
  visibility: hidden;
}




.overlay100{
  @extend %overlay100;
}

.media-collection{
  @extend %wh100PC;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  *{
    pointer-events: auto;
  }
}


.text-read{
  white-space: pre-line;
}


.media-image{
  position: absolute;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  max-height:100%;
  max-width:100%;
  //object-fit: contain;
}
.media-image-dual{
  @extend .media-image;
}

.media-image-slider-compare{
  position: relative;
  -webkit-appearance: none;
  height: 100%;
  background-color: transparent;
  outline: none;
}

.media-image-slider-compare::-webkit-slider-thumb{
  -webkit-appearance: none;
  height: 38px;
  width: 38px;
  background: rgba(255,255,255,0.3);
  border: 4px solid white;
  border-radius: 50%;
  background-size: contain;
  cursor: pointer;
}




.canvas-spectrum{
  align-self: center;
  justify-self: center;

  width: 100pc;
  height: auto;

}


.info-container{
  @extend .text-read;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding:20px;

  .section{
    margin-bottom: 20px;
  }

  .label{
    font-weight: bold;
    // font-size: large;
  }
}

.info-container{
  @extend .info-container;
  .text-bock{
    display:inline-block;
  }
}


.spacer {
  flex: 1 1 auto;
}
.stretchToFit{
  flex: 1;
}
.h100pc{
  height: 100%;
}
.w100pc{
  width: 100%;
}
.wh100PC{
  @extend .h100pc , .w100pc;
}


.app-container{
  @extend .h100pc;
  display: flex;
  flex-direction: column;
}

.component-container{
  @extend .wh100PC;
  display: flex;
  flex-direction: column;
}

.home-container{
  @extend .component-container;
  padding: 20px;
  align-items: center;
  justify-content: center;

  .home-info{
    @extend .stretchToFit;
    overflow-y: scroll;
    margin: 20px 20px;

    img{
      display: block;
      margin: 20px 0px;
    }

  }

}




.split-container{
  @extend .stretchToFit;
}
.split-area{
  @extend .h100pc;
  display: flex;
  flex-direction: column;
}

.as-split-gutter{
  // background-color: #dddddd !important;
  box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%);
}



.graphics{
  @extend .h100pc;
  background-color: black;
  color: #FFFFFF;
  position: relative;
}

// titulo del media item seleccionado (centrado abajo)
.media-item-title{
  padding: 5px;
  background-color: black;
  color: white;
  font-size: 12px;
  text-align: center;
}


.toolbar-icon{
  z-index: 2;
  justify-content: center;

  .toolbar-icon-button{
    color: #666666;

    &.selected{
      background-color: #666666;
      color: #ffffff;
    }
  }
}




.information{
  @extend .h100pc;
  color: #000000;
  position: relative;
}

.tip{
  @extend .h100pc , .w100pc;
  position: absolute;
  left:0;
  top:0;
  background-color: rgba(0 , 0 , 0 , 0.4 );
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(1px);
  span{
    display: block;
    padding: 20px;
    background-color: rgba(255 , 255 , 255 , 0.8 );
    font-size: 10px;
    color: black;
    border-radius: 20px;
    user-select: none;
    max-width: 70%;
  }
}











.audioPlayer{
  background-color: black;
  display: flex;
  flex-direction: row;
  .controls{
    display: block;
  }
  .progress{
    @extend .stretchToFit;
    display: flex;
    align-items: center;
    position: relative;
    .runner{
      position: absolute;
      background-color: #FFFFFF;
      height: 10%;
    }
    .road{
      position: absolute;
      background-color: #333333;
      height: 10%;
      width: 100%;
    }
  }

  .volume{
    display: block;
  }


  .time{
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
    font-size: 14px;
    font-family: Roboto;
    color: white;
  }
}





app-scanner-dialog{
  .header{
    display: flex;
    flex-direction: row;
    .title{
      flex: 1
    }
    mat-icon{
      margin-left: 20px;
    }
  }

  .stream{
    @extend .w100pc , .invalid;
    margin-top: 20px;
    margin-bottom: 20px;
    flex: 1;
    background-color: #333333;
  }

  .href{
    @extend .invalid;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #DDDDDD;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;

    display: flex;
    min-height: 50px;
    align-items: center;
  }

  .invalid{
    box-sizing: border-box;
    border: 2px solid red;
  }

  .valid{
    box-sizing: border-box;
    border: 2px solid green !important;
  }

}




.drawer-container{
  @extend .stretchToFit; // , .wh100PC; //

  .drawer-sidenav{
    @extend .h100pc;
    max-width: 70%;
  }

  .drawer-content{
    @extend .h100pc;
    // display: flex;
    // justify-content: center;
  }

}



.mat-list-text {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.favorites-container{
  @extend .text-read;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0px;
}








// Hotspots genericos -----------------------------------------------

.hotspot-base{
  position: absolute;
  font-size: small !important;
  color: white !important;
  width: 0px;
  height: 0px;
}

.hotspot-icon{
  user-select: none;
  width: 26px;
  height: 26px;
  background-color: black;
  border-radius: 50%;
  border: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50% , -50%);
}


.hotspot-label{
  font-size: small;
  transform: translate(-50% , -250%);

  display: block;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5em 1em;
}



// Pannellum --------------------------------------------------------
.pnlm-info{
  //
}

.pnlm-hotspot{
  font-size: small;
  span{
    //
  }
}



// Model Viewer --------------------------------------------------------

// Circulito ....
.model-hotspot {
  --min-hotspot-opacity: 0.1;
  @extend .hotspot-base;
}

.model-info {
  @extend .hotspot-icon;
}


.model-label {
  @extend .hotspot-label;
}


// Cylinder Viewer --------------------------------------------------------

.cylinder-hotspot-container{
  position: absolute;
  pointer-events: none ;
  * {
    pointer-events: auto;
  }
}

.cylinder-hotspot{
  @extend .hotspot-base;
}

.cylinder-info{
  @extend .hotspot-icon;
}
.cylinder-label{
  @extend .hotspot-label;
}





.gigapixel-hotspot{
  @extend .hotspot-base;
  text-decoration: none;
}
.gigapixel-info{
  @extend .hotspot-icon;
}
.gigapixel-label{
  @extend .hotspot-label;
}



